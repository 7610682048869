import { StaticImageData } from 'next/image'
import { ReactNode } from 'react'

export type Media = {
  type: 'image' | 'video' | 'audio'
  src: string | StaticImageData
  b64preview?: string
  position?: string
  alt: string
  description: string
  width?: number
  height?: number
}
export type RemoteVideo = {
  kind: 'vimeo' | 'youtube'
  url: string
}

export type Location = {
  name: string
  fullname: string
  address: string
  url: string
}
export const Locations: Record<string, Location> = {
  Online: {
    name: 'online',
    fullname: 'online',
    address: '',
    url: ''
  },
  BruceCurtis: {
    name: 'Bruce Curtis Bldg W100 ',
    fullname: 'The Bruce Curtis Building Museum Collections, Room W100',
    address: '1440 Central Campus Mall, Boulder, CO 80302',
    url: 'https://www.google.com/maps/place/Bruce+Curtis+Building,+Boulder,+CO+80309/@40.0075106,-105.2767036,17z/data=!3m1!4b1!4m6!3m5!1s0x876bec313b0505eb:0xa90b392c2480a17f!8m2!3d40.0075065!4d-105.2741287!16s%2Fg%2F1q5bm3ctw?entry=ttu'
  },

  Hale270: {
    name: 'Hale 270',
    fullname: 'Hale Science, Room 270',
    address: '1350 Pleasant St, Boulder, CO 80302',
    url: 'https://www.google.com/maps/place/Hale+Science,+1350+Pleasant+St,+Boulder,+CO+80302/@40.0091245,-105.2762716,18z/data=!4m6!3m5!1s0x876bec31aae5ecc3:0xec7311aa73bda663!8m2!3d40.009216!4d-105.2749548!16s%2Fg%2F1q5bmh4h_'
  },
  Eaton150: {
    name: 'Eaton 150',
    fullname: 'Eaton Humanities Bldg, Room 150',
    address: '1610 Pleasant St, Boulder, CO 80302',
    url: 'https://www.google.com/maps/place/Eaton+Humanities+Bldg,+1610+Pleasant+St,+Boulder,+CO+80302/@40.0091606,-105.2739175,17z/data=!3m1!4b1!4m6!3m5!1s0x876bec33d4092f1f:0x60b5f3ccf5fc9bef!8m2!3d40.0091565!4d-105.2717288!16s%2Fg%2F11clftgzc9'
  },
  MAL: {
    name: 'Media Archaeology Lab',
    fullname: 'Media Archaeology Lab',
    address: '1320 Grandview Ave. Boulder CO 80302',
    url: 'https://www.google.com/maps/place/Media+Archaeology+Lab/@40.0112691,-105.2776527,17z/data=!3m1!4b1!4m6!3m5!1s0x876bec30295c1719:0xed41efaf5a99f1da!8m2!3d40.011265!4d-105.275464!16s%2Fg%2F11cnbvlmwy'
  },
  Studio7: {
    name: 'Studio 7',
    fullname: 'Stadium, Gate 7, Suite 255',
    address: 'Stadium Building, 2150 Stadium Dr, Boulder, CO 80309',
    url: "https://www.google.com/maps/place/40%C2%B000'32.6%22N+105%C2%B015'57.0%22W/@40.0090493,-105.2658276,17z/data=!3m1!4b1!4m4!3m3!8m2!3d40.0090493!4d-105.2658276"
  },
  BTULab: {
    name: 'BTULab',
    fullname: 'Blow Things Up (BTU) Lab',
    address: 'Roser ATLAS Center, building number 231 University of Colorado Boulder 1125 18th Street level 1, Boulder, CO 80302',
    url: "https://www.google.com/maps/place/ATLAS+Institute,+University+of+Colorado+Boulder/@40.007747,-105.272538,17z/data=!3m1!4b1!4m6!3m5!1s0x876bec3384ff175f:0xe59d1ef9575505f5!8m2!3d40.0077429!4d-105.2699631!16s%2Fg%2F1tjyv2nf?entry=ttu"
  },
  B2: {
    name: 'B2',
    fullname: 'B2 Center for Media, Art and Performance',
    address: 'Roser ATLAS Center, building number 231 University of Colorado Boulder 1125 18th Street Basement level 2, Boulder, CO 80302',
    url: "https://www.google.com/maps/place/B2+Center+for+Media,+Art+and+Performance/@40.0077306,-105.2744584,17z/data=!3m1!4b1!4m6!3m5!1s0x876bed853f283a6b:0x5cd703de9f0c7b7c!8m2!3d40.0077266!4d-105.2699737!16s%2Fg%2F11qnlw4n96"
  },
  Atlas100: {
    name: 'Atlas 100',
    fullname: 'Atlas 100',
    address: 'Roser ATLAS Center, building number 231 University of Colorado Boulder 1125 18th Street Basement level 1, Boulder, CO 80302',
    url: "https://www.google.com/maps/place/ATLAS+Institute,+University+of+Colorado+Boulder/@40.007747,-105.272538,17z/data=!3m1!4b1!4m6!3m5!1s0x876bec3384ff175f:0xe59d1ef9575505f5!8m2!3d40.0077429!4d-105.2699631!16s%2Fg%2F1tjyv2nf?entry=ttu"
  },
  Atlas102: {
    name: 'Atlas 102',
    fullname: 'Atlas 102',
    address: 'Roser ATLAS Center, building number 231 University of Colorado Boulder 1125 18th Street Basement level 1, Boulder, CO 80302',
    url: "https://www.google.com/maps/place/ATLAS+Institute,+University+of+Colorado+Boulder/@40.007747,-105.272538,17z/data=!3m1!4b1!4m6!3m5!1s0x876bec3384ff175f:0xe59d1ef9575505f5!8m2!3d40.0077429!4d-105.2699631!16s%2Fg%2F1tjyv2nf?entry=ttu"
  },

  CaseW311: {
    name: 'Case W311',
    fullname: 'CU Boulder Center for Academic Success and Engagement (CASE)',
    address: '1725 Euclid Ave, Boulder, CO 80309',
    url: "https://www.google.com/maps/place/CU+Boulder+Center+for+Academic+Success+and+Engagement+(CASE)/@40.0063478,-105.2724136,17z/data=!3m2!4b1!5s0xa12a9552749be31:0x7a6b65fa5315e6c7!4m6!3m5!1s0x876bed587fc685ef:0x35637988d88eb13e!8m2!3d40.0063437!4d-105.2702249!16s%2Fg%2F11fcpdpl6t"
  },
  VA1B20: {
    name: 'Visual Arts 1B20',
    fullname: 'Visual Arts Complex, Room 1B20',
    address: '1085 18th St, Boulder, CO 80302',
    url: "https://www.google.com/maps/place/Visual+Arts+Complex,+Department+of+Art+%26+Art+History/@40.0074333,-105.2749845,17z/data=!3m1!4b1!4m6!3m5!1s0x876bec338c7b78e3:0x7854efda2822a93c!8m2!3d40.0074293!4d-105.2704998!16s%2Fg%2F1tm0tn7s"
  }
}

export type EventKind =
  | 'talk'
  | 'presentation'
  | 'seminar'
  | 'roundtable'
  | 'workshop'
  | 'performance'
  | 'screening'

export type Event = {
  kind: EventKind
  title: string
  subtitle?: string
  location: Location
  start: Date
  end: Date
  blurb: string
  blurbJSX?: ReactNode
  media: Array<Media>
  recording?: RemoteVideo
}

export type LogoNames = 'atlas' | 'cdem' | 'dcmp' | 'wavefarm' | 'iawp' | 'mal' | 'bmkoes' | 'ima' | 'cmci' | 'b2' | 'roser' | 'missionzero' | 'medlab' | 'libraries' | 'english' | 'mediastudies' | 'cha' | 'thdn' | 'leaf'

export type Visitor = {
  name: string
  slug: string
  logos: Array<LogoNames>
  bio: string
  bioJSX?: ReactNode
  events: Array<Event>
  profile: Media
  urls: Array<string>
}

export type LogoList = Record<LogoNames, { fullname: string, url: string }>

export const allLogos: LogoList = {
  'atlas': { fullname: 'ATLAS', url: 'https://www.colorado.edu/atlas/' },
  'cdem': { fullname: 'The Center for Documentary and Ethnographic Media', url: 'https://www.colorado.edu/center/cdem/' },
  'dcmp': { fullname: 'The Depeartment of Critical Media Practices', url: 'https://www.colorado.edu/cmci/dcmp' },
  'wavefarm': { fullname: 'Wave Farm', url: 'https://wavefarm.org' },
  'iawp': { fullname: 'Intermedia Art, Writing and Performance (PhD)', url: 'https://www.colorado.edu/cmci/academics/iawp' },
  'mal': { fullname: 'Media Archaeology Lab', url: 'https://www.mediaarchaeologylab.com/' },
  'bmkoes': { fullname: 'Das Bundesministerium für Kunst, Kultur, öffentlichen Dienst und Sport', url: 'https://www.bmkoes.gv.at/en.html' },
  'ima': { fullname: 'Institut für Medienarchäologie', url: 'https://ima.or.at/' },
  'cmci': { fullname: 'College of Media, Communication and Information ', url: 'https://www.colorado.edu/cmci/' },
  'b2': { fullname: 'B2', url: 'https://www.colorado.edu/atlas/b2' },
  'roser': { fullname: 'The Roser Visiting Artist Program', url: 'https://www.colorado.edu/rosergrant/grants' },
  'missionzero': { fullname: 'Mission Zero', url: 'https://missionzero.io/' },
  'medlab': { fullname: 'Media Economies Design Lab', url: 'https://www.colorado.edu/lab/medlab' },
  'libraries': { fullname: 'University Libraries', url: 'https://www.colorado.edu/libraries' },
  'english': { fullname: 'The Department of English', url: 'https://www.colorado.edu/english' },
  'mediastudies': { fullname: 'Media Studies', url: 'https://www.colorado.edu/cmci/academics/media-studies' },
  'cha': { fullname: 'Center for Humanities & the Arts', url: 'https://www.colorado.edu/cha' },
  'leaf': { fullname: 'Lafayette Electronic Arts Festival', url: 'https://leafcolorado.org/' },
  'thdn': { fullname: 'Theater & Dance', url: 'https://www.colorado.edu/theatredance/' }
}
